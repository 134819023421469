import crewordRequest from "./crewordRequest";
import request from "./request";

//获取页面列表
export function findTemplateByTypeId(data) {
  return crewordRequest({
    url: "/template/findTemplateByTypeId",
    method: "post",
    data,
  });
}
//模板搜索
export function templateSearch(data) {
  return crewordRequest({
    url: "/template/templateSearch",
    method: "post",
    data,
  });
}

// 模板购买列表
export function findUserBuyTemplate(data) {
  return crewordRequest({
    url: "/template/findUserBuyTemplate",
    method: "post",
    data,
  });
}

export function openTemplateFile(data) {
  return crewordRequest({
    url: "/analysis/openTemplateFile",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

//获取主题
export function findTemplateTypeByLibId(data) {
  return crewordRequest({
    url: "/template/findTemplateTypeByLibId",
    method: "post",
    data,
  });
}

//添加与取消收藏
export function addTemplateCollect(data) {
  return crewordRequest({
    url: "/template/addTemplateCollect",
    method: "post",
    data,
  });
}

//获取已选择和未选择模板库
export function findTemplateOptionalLibrary(data) {
  return crewordRequest({
    url: "/template/findTemplateOptionalLibrary",
    method: "post",
    data,
  });
}

//点击添加模板库
export function addUserTempLibrary(data) {
  return crewordRequest({
    url: "/template/addUserTempLibrary",
    method: "post",
    data,
  });
}

//点击添加模板库
export function delUserTempLibrary(data) {
  return crewordRequest({
    url: "/template/delUserTempLibrary",
    method: "post",
    data,
  });
}

//获取用户收藏模板
export function findUserCollectTemplate(data) {
  return crewordRequest({
    url: "/template/findUserCollectTemplate",
    method: "post",
    data,
  });
}

//热度增加 接口
export function addTemplateHot(data) {
  return crewordRequest({
    url: "/template/addTemplateHot",
    method: "post",
    data,
  });
}

// 埋点接口
export function templateDownload(params) {
  return crewordRequest({
    url: "/contextual/templateDownload",
    method: "GET",
    params,
  });
}

// 模板选择购买
export function chooseBuy(data) {
  return crewordRequest({
    url: "/template/chooseBuy",
    method: "post",
    data,
  });
}

// 添加模板订单
export function otherorderAdd(data) {
  return request({
    url: "/otherorder/add",
    method: "post",
    data,
  });
}

//添加汇编
export function addAssemblyInfo(data) {
  return crewordRequest({
    url: "/template/addAssemblyInfo",
    method: "post",
    data,
  });
}

//统计添加汇编数量
export function userAssemblyCount(data) {
  return crewordRequest({
    url: "/template/userAssemblyCount",
    method: "post",
    data,
  });
}

//汇编成册
export function writerMergeWord() {
  return crewordRequest({
    url: "/template/writerMergeWord",
    method: "post",
    responseType: "blob",
  });
}

//汇编成册
export function delDocTempInfo(data) {
  return crewordRequest({
    url: "/template/delDocTempInfo",
    method: "post",
    data,
  });
}

//批量下载
export function writerTemplate(data) {
  return crewordRequest({
    url: "/template/writerTemplate",
    method: "post",
    data,
    responseType: "blob",
  });
}
//评论列表
export function commentList(data) {
  return crewordRequest({
    url: "/template/comment/list",
    method: "post",
    data,
  });
}
//点赞/取消
export function commentPraise(data) {
  return crewordRequest({
    url: "/template/comment/praise",
    method: "post",
    data,
  });
}
//发布评论
export function commentAdd(data) {
  return crewordRequest({
    url: "/template/comment/add",
    method: "post",
    data,
  });
}
//添加模板下载日志
export function logAdd(data) {
  return crewordRequest({
    url: "/template/log/add",
    method: "post",
    data,
  });
}
//插入文库记录的
export function recordAdd(data) {
  return crewordRequest({
    url: "/wordDownload/record/add",
    method: "post",
    data,
  });
}

// 模板详情
export function wordToHtml(data) {
  return request({
    url: "/common/templateWordToHtml",
    method: "post",
    data,
  });
}

//获取主题
export function findOftenLibraryType(params) {
  return crewordRequest({
    url: "/contextual/findByPid",
    method: "get",
    params,
  });
}

//  查询选中库
export function findSelectedLib(params) {
  return crewordRequest({
    url: "/contextual/findSelectedLib",
    method: "get",
    params,
  });
}

//查询常用语句
export function findOftenWord(params) {
  return crewordRequest({
    url: "/contextual/pageList",
    method: "get",
    params,
  });
}

// 成语券支付
export function deductIdiomCoupon(data) {
  return request({
    url: "/userInterests/deductIdiomCoupon",
    method: "post",
    data,
  });
}
