import request from "./request";
import crewordRequest from "./crewordRequest";
// 获取语境算法引擎
export function getProductListGroup(data) {
  return request({
    url: "/side/getProductListGroup",
    method: "post",
    data,
  });
}
// 续写
export function recommendRenewal(data) {
  return crewordRequest({
    url: "/recommend/renewal",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
export function checkIdiomCoupon(data) {
  return request({
    url: "/userInterests/checkIdiomCoupon",
    method: "post",
    data,
  });
}
// 获取侧边栏数据
export function choiceTreeList(data) {
  return crewordRequest({
    url: "/aiwriting/choiceTreeList",
    method: "get",
    data,
  });
}
// 获取商品列表
export function queryProducts(data) {
  return crewordRequest({
    url: "/aiwriting/queryProducts",
    method: "post",
    data,
  });
}
// 获取商品详情列表
export function caseSearch(data) {
  return crewordRequest({
    url: "/case/caseSearch",
    method: "post",
    data,
  });
}
// 加入购物车
export function saveGoshopp(data) {
  return crewordRequest({
    url: "/carts/save",
    method: "post",
    data,
  });
}
// 查看详情
export function caseDetail(data) {
  return crewordRequest({
    url: "/case/caseDetail",
    method: "post",
    data,
  });
}
// 购物车页面
export function getList(data) {
  return crewordRequest({
    url: "/carts/list",
    method: "get",
    data,
  });
}
// 支付
export function submit(data) {
  return crewordRequest({
    url: "/carts/submit",
    method: "post",
    data,
  });
}

export function buyCase(data) {
  return crewordRequest({
    url: "/carts/buyCase",
    method: "post",
    data,
  });
}

// /case/buyList
export function buyList(data) {
  return crewordRequest({
    url: "/case/buyList ",
    method: "post",
    data,
  });
}
// /carts/delete
export function deletes(data) {
  return crewordRequest({
    url: "/carts/delete ",
    method: "post",
    data,
  });
}
// 读书文案
export function readingCopyPageList(data) {
  return crewordRequest({
    url: "/aiwriting/readingCopyPageList",
    method: "post",
    data,
  });
}
// 读书文案列表
export function readingCasePageList(data) {
  return crewordRequest({
    url: "/aiwriting/readingCasePageList",
    method: "post",
    data,
  });
}
// 整本书的价格
export function findCasePrice(data) {
  return crewordRequest({
    url: "/case/findCasePrice",
    method: "post",
    data,
  });
}
// 投资性购买
export function invest(data) {
  return crewordRequest({
    url: "/carts/invest",
    method: "post",
    data,
  });
}

export function logUserDisplay(data) {
  return crewordRequest({
    url: "/corpuslog/logUserDisplay",
    method: "post",
    data,
  });
}
