<template>
  <div class="Leadership">
    <!-- <div class="back" @click="$router.go(-1)">
      <i class="el-icon-arrow-left"></i> 返回
    </div> -->
    <div class="shopp">
      <div class="left9">
        <img src="~assets/writtenAi/youwriytt.png" alt="" />
      </div>
      <div class="right">
        <div class="right_top">
          <div class="ri_top_le">
            <h2>{{ objInfo.info ? objInfo.info.name : '' }}</h2>
            <h4>根据输入的主题、选择项自动匹配生成文章</h4>
          </div>
          <div class="Sample">样稿</div>
        </div>
        <div class="right_bottom">
          <div class="ri_bt_le">
            <div class="ri_hd">
              成语券： <span>{{ pase }}</span> 券/篇
            </div>
            <div class="ri_cd">
              <div class="ri_title">篇幅规格：</div>
              <div
                class="box"
                v-for="(item, idx) in objInfo.specification"
                :key="idx"
                :class="datanum == item.id ? 'wit' : ''"
                @click="Specifications(item.id, item.price)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="go" @click="go">购买</div>
        </div>
      </div>
    </div>
    <div class="introduce">
      <div class="info">商品介绍</div>
      <div class="cont">
        <div class="cont_o">
          <div class="cont_to">
            <h2>1. 领导主题讲话AIGC稿</h2>
            <h4>根据输入的主题、选择项自动匹配生成文章</h4>
          </div>
          <div class="cont_bt">
            <h2>2. 领导主题讲话AIGC稿</h2>
            <h4>根据输入的主题、选择项自动匹配生成文章</h4>
          </div>
        </div>
        <div class="cont_o">
          <div class="cont_to">
            <h2>1. 领导主题讲话AIGC稿</h2>
            <h4>根据输入的主题、选择项自动匹配生成文章</h4>
          </div>
          <div>
            <h2>2. 领导主题讲话AIGC稿</h2>
            <h4>根据输入的主题、选择项自动匹配生成文章</h4>
          </div>
        </div>
        <div class="cont_o">
          <div class="cont_to">
            <h2>1. 领导主题讲话AIGC稿</h2>
            <h4>根据输入的主题、选择项自动匹配生成文章</h4>
          </div>
          <div>
            <h2>2. 领导主题讲话AIGC稿</h2>
            <h4>根据输入的主题、选择项自动匹配生成文章</h4>
          </div>
        </div>
      </div>
    </div>
    <!-- 检验成语券弹框 -->
    <ContinuationTips
      :Tipsmessage="currentTips"
      :isShow="isShow"
      :number="number"
      ref="ContinuationTips"
    />
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { findCaseSpecification } from 'api/writtenAi'
import { checkIdiomCoupon } from 'api/Continuation'
import ContinuationTips from 'components/ContinuationTips'
import qs from 'qs'
export default {
  name: 'Leadership',
  //import引入的组件需要注入到对象中才能使用
  components: { ContinuationTips },
  data() {
    //这里存放数据
    return {
      datanum: 1,
      pase: 9.9,
      objInfo: {},
      isShow: false,
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    async findCaseSpecifications() {
      let res = {
        labelId: this.$route.query.labelId,
      }
      console.log(res)
      let data = await findCaseSpecification(qs.stringify(res))
      this.objInfo = data.data
    },
    Specifications(id, pas) {
      this.datanum = id
      this.pase = pas
    },
    async go() {
      this.currentTips = ''
      let res = await checkIdiomCoupon({
        type: 10,
        num: this.pase,
      })
      if (res.code == 200) {
        this.$router.push({
          path: '/DraftAIGC',
          query: { id: 1, anum: this.datanum, pase: this.pase },
        })
      } else if (res.code == 9008) {
        this.currentTips = res.message
        this.$refs.ContinuationTips.numbers('0.1997')
        this.isShow = true
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.findCaseSpecifications()
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="scss" scoped>
.Leadership {
  .back {
    width: 100%;
    height: 53px;
    padding-left: 12px;
    box-sizing: border-box;
    line-height: 53px;
    color: #999;
    cursor: pointer;
  }
  .shopp {
    display: flex;

    width: 100%;
    height: 200px;
    padding: 12px 11px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 4px 13px 0px rgba(205, 205, 205, 0.31);
    border-radius: 10px;
    .left9 {
      width: 176px;
      height: 176px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .right {
      flex: 1;
      margin-left: 50px;

      padding: 15px 0;
      .right_top {
        width: 100%;
        display: flex;
        .ri_top_le {
          flex: 1;
        }
        h2 {
          font-size: 18px;
          color: #333;
          margin-bottom: 18px;
        }
        h4 {
          font-size: 14px;
          font-weight: 500;
          color: #999;
        }
        .Sample {
          cursor: pointer;
          margin-top: 6px;
          width: 89px;
          height: 39px;
          background: #ffffff;
          border: 2px solid #cbd6ff;
          border-radius: 19px;
          line-height: 37px;
          text-align: center;

          color: #4587ff;
        }
      }
      .right_bottom {
        display: flex;
        width: 100%;
        margin-top: 22px;
        .ri_bt_le {
          flex: 1;
        }
        .ri_hd {
          font-size: 14px;
          color: #999;
          span {
            color: #ff6500;
          }
        }
        .ri_cd {
          display: flex;
          align-items: center;
          margin-top: 18px;
          .ri_title {
            font-size: 14px;
            color: #999;
          }

          .box {
            cursor: pointer;
            width: 75px;
            height: 38px;
            background: #f7f8fa;
            border: 1px solid #f7f8fa;
            border-radius: 8px;
            color: #333;
            line-height: 38px;
            text-align: center;
            margin: 0 40px;
            &:nth-child(1) {
              margin-left: 0;
            }
            &:hover {
              width: 75px;
              height: 38px;
              background: #eaf0ff;
              border: 1px solid #89aaff;
              border-radius: 8px;

              color: #4587ff;
            }
          }
          .wit {
            width: 75px;
            height: 38px;
            background: #eaf0ff;
            border: 1px solid #89aaff;
            border-radius: 8px;

            color: #4587ff;
          }
        }
        .go {
          cursor: pointer;
          margin-top: 13px;
          width: 89px;
          height: 39px;
          background: #ffebde;
          border: 2px solid #ffdbc5;
          border-radius: 19px;
          color: #ff6500;
          line-height: 39px;
          text-align: center;
        }
      }
    }
  }
  .introduce {
    margin-top: 43px;
    padding-left: 25px;
    padding-bottom: 15px;
    box-sizing: border-box;
    width: 100%;

    background: linear-gradient(0deg, #fffaf4, #fffefb);
    border-radius: 10px;
    .info {
      width: 133px;
      height: 54px;
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
      line-height: 54px;
      padding-left: 20px;
      box-sizing: border-box;
      background: url('~assets/writtenAi/info.png') no-repeat;
      background-size: 100% 100%;
    }
    .cont {
      margin-top: 33px;
      display: flex;
      justify-content: space-around;
      .cont_o {
        flex: 1;
        line-height: 28px;
        .cont_to {
          margin-bottom: 20px;
          margin-left: 10px;
        }
        h2 {
          font-size: 14px;

          color: #333333;
        }
        h4 {
          font-size: 14px;
          font-weight: 500;
          color: #999;
        }
      }
    }
  }
}
</style>
