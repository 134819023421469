<template>
  <div class="mask" v-if="isShow">
    <div class="idiomTips">
      <div class="box">
        <div class="header">
          <img :src="require('assets/images/ai-chentu.png')" alt="" />
        </div>

        <div class="content">
          <div class="text">{{ Tipsmessage }}</div>
          <div class="sun" v-if="!Tipsmessage">
            本次操作扣除 <span class="num">{{ num }}</span> 张成语券
          </div>
          <div class="surplus">
            剩余成语券:
            <span>{{ Tipsmessage ? idiomCoupon : idiomnum }}</span> 张
          </div>

          <!-- <div class="num">(1次/{{ vouchersNum }}券)</div> -->

          <div class="btn_box">
            <div class="btn_item" @click="$router.push('/details/973')">
              购买成语券
            </div>
            <div class="btn_item" v-if="!Tipsmessage" @click="ticketPay">
              确认支付
            </div>
          </div>

          <div class="choose" v-if="!Tipsmessage">
            <el-checkbox v-model="checked" @change="checkedClick"></el-checkbox
            >记住我的选择(当成语券充足的时候直接扣除,不再提示)
          </div>
          <div class="close" @click="closeClick">
            <img :src="require('assets/images/ai-close.png')" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//例如：import  from '';
import { deductIdiomCoupon } from 'api/search'
import { amendUpdate, settings } from 'api/service'
import { getuserInfo } from 'api/service'
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },

    dataType: {},
    vouchersNum: {
      default: 1,
    },
    Tipsmessage: {
      default: '',
    },
  },

  name: 'idiomTips',
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      idiomCoupon: 0,
      // 剩余成语券
      idiomnum: 0,
      num: 0,
      checked: false,
      id: 0,
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 记住勾选
    async checkedClick(val) {
      let params = {
        aiImg: val ? 1 : 0,
      }
      await amendUpdate(params)
    },
    closeClick() {
      this.$parent.isShow = false
    },
    async ticketPay() {
      if (!this.Tipsmessage) {
        this.$parent.isShow = false
        if (this.id == 3) {
          this.$parent.invests()
        } else if (this.id == 999) {
          this.$parent.deductIdiomCoupons()
        } else {
          this.$parent.submits()
        }
      }
    },
    async setting() {
      let data = await settings()
      console.log(data)
      if (data.data.aiImg == 0) {
        this.checked = false
      } else {
        this.checked = true
      }
    },

    async getUserinfo() {
      const res = await getuserInfo()
      if (res.code == 200) {
        if (this.num == 0.1997) {
          this.idiomCoupon = res.data.idiomCoupon
        } else {
          this.idiomCoupon = res.data.idiomCoupon
          this.idiomnum = this.idiomCoupon - this.num
          this.idiomnum = this.idiomnum.toFixed(2)
        }
      }
    },
    numbers(val, id) {
      this.num = val
      this.id = id
      this.getUserinfo()
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // this.$bus.$on('numbers', (val) => {
    //   this.num = val
    //   this.getUserinfo()
    // })
    this.setting()
    this.getUserinfo()
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  // destroyed() {
  //   this.$bus.$off('numbers')
  // },
}
</script>
<style lang="scss" scoped>
.mask {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
  height: 100%;
}
.idiomTips {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);

  .box {
    position: relative;
    width: 387px;
    height: 360px;
    background-image: url('~@/assets/images/ai-tips-background.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .header {
      width: 132px;
      height: 93px;
      position: absolute;
      top: 30px;
      left: 50%;
      transform: translateX(-50%);
      img {
        width: 100%;
        height: 100%;
      }
    }

    .content {
      //   height: 100%;
      transform: translateY(170px);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      .sun {
        span {
          color: #ff6900;
        }
      }

      > div {
        text-align: center;
      }
      .text {
        font-weight: bold;
        font-size: 17px;
        color: #333335;
        text-align: center;
        padding: 0 20px;
      }

      .btn_box {
        margin: 15px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .btn_item {
          margin: 0 10px;
          width: 40%;
          box-sizing: border-box;
          padding: 10px 20px;
          cursor: pointer;
          border-radius: 5px;

          &:nth-child(1) {
            border: 1px solid #ff6900;
          }
          &:nth-child(2) {
            color: #fff;
            background-color: #ff6900;
          }
        }
      }

      .surplus {
        margin: 15px 0;
        span {
          color: #ff6900;
        }
      }

      .close {
        border-radius: 50%;
        background: #fff;
        position: absolute;
        bottom: -100px;
        width: 28px;
        height: 28px;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .choose {
    padding: 0 10px;
    box-sizing: border-box;
    font-size: 14px;
  }
}
</style>
