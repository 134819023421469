import request from "./request";
import crewordRequest from "./crewordRequest";

// 生成内容
export function RecommendCorpusEssay(data) {
  return crewordRequest({
    url: "/recommend/corpusEssay",
    method: "post",
    data,
  });
}
// 类型搜索
export function CorpusTypeSelection(data) {
  return crewordRequest({
    url: "/corpus/typeSelection",
    method: "post",
    data,
  });
}
// word下载
export function htmlToWordDownload(data) {
  return request({
    url: "/common/htmlToWordDownload",
    method: "post",
    data,
    responseType: "blob",
  });
}
// pdf下载
export function htmlTopdfDownload(data) {
  return request({
    url: "/common/htmlToPdfDownload",
    method: "post",
    data,
    responseType: "blob",
  });
}
// 地区
export function RegionSelection(data) {
  return crewordRequest({
    url: "/adress/regionSelection",
    method: "post",
    data,
  });
}
export function findCaseSpecification(data) {
  return crewordRequest({
    url: "/case/findCaseSpecification",
    method: "post",
    data,
  });
}
// 支付成语券
export function deductIdiomCoupon(data) {
  return request({
    url: "/userInterests/deductIdiomCoupon",
    method: "post",
    data,
  });
}
